declare const country: string;

import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";

const baseURL = "/embed/?map=world&projection=geoOrthographic&theme=light-green&water=1&graticule=1&names=1&duration=2000&slider=0&autoplay=1&autozoom=none";
const baseStep = "&places=~~0.8_4.6_29.5_-4.4_-29.3";

const continentAnimations: any = {
  "Europe": "Europe~GB_FR_IT_CH_DE_PL_LT_LV_EE_FI_SE_UA~2.8_16.3_51.8_-18.6_-53.9",
  "North America": "North%20America~US_CA_MX~1.5_-98.9_41.8_105.8_-46.4",
  "South America": "South%20America~PE_BR_BO~1.5_-62.7_-22.4_68.2_18.1",
  "Africa": "Africa~MG_MZ_TZ_KE_ZA~2_28.3_-14_-26.9_9.4",
  "Asia": "Asia~IN_CN_KR_JP_VN_KH_TH_LA~1.5_105_18.6_-104.3_-23",
  "Oceania": "Oceania~AU_NZ_FJ~2.3_137.9_-30.5_-128.8_28"
}

const extraMaps: any[] = [{
  country: "Canada (Counties)",
  map: "region_canada_canadaCounties",
  extra: "Counties"
}, {
  country: "Mexico (Counties)",
  map: "region_mexico_mexicoCounties",
  extra: "Counties"
}, {
  country: "United States (Counties)",
  map: "region_usa_usaCounties",
  extra: "Counties"
}, {
  country: "World",
  map: "worldChina",
  extra: "China version"
}, {
  country: "World",
  map: "worldIndia",
  extra: "India version"
}, {
  country: "World",
  map: "worldMorocco",
  extra: "Morocco version"
}];

function app() {
  let maps: any[] = [];
  Object.keys(am5geodata_data_countries2).forEach(function(key: string) {
    am5geodata_data_countries2[key].maps.forEach((map: string) => {
      if (map.match(/Low$/)) {
        maps.push({
          country: am5geodata_data_countries2[key].country,
          map: map.replace("Low", "")
        });
      }
    })
  });

  maps = maps.concat(extraMaps);

  maps.sort((a: any, b: any) => {
    if (a.country > b.country) return 1;
    if (a.country < b.country) return -1;
    return 0;
  });

  const optgroup = document.getElementById("countrymaps")!;

  let prevCountry: string = "";
  let prevMap: string = "";
  maps.forEach((map: any) => {
    if (map.country.match(/Russia/)) {
      return;
    }
    const option = document.createElement("option");
    option.value = map.map;
    if (prevCountry == map.country || map.extra) {
      if (prevMap.match("2")) {
        return;
      }
      const add = map.map.replace(prevMap, "");
      const info = add.replace(/([A-Z0-9]+)/g, " $1").trim().split(" ");
      const extra = map.extra || "w/ " + info.join(" ");
      if (extra) {
        option.innerHTML = map.country + " (" + extra + ")";
      }
      else {
        option.innerHTML = map.country;
      }
    }
    else {
      option.innerHTML = map.country;
      prevCountry = map.country
      prevMap = map.map;
    }
    optgroup.appendChild(option);
  });

  document.getElementById("start")!.addEventListener("click", () => {
    const select = document.getElementById("map")! as HTMLSelectElement;
    const option = select.options[select.selectedIndex];
    const map = option.value;
    window.location.href = "/" + map + "/" + (option.getAttribute("data-template") || "");
  });

  // Determine user's country/continent and generate proper animation
  let url = baseURL;
  let continent: string = "";
  if (typeof country !== "undefined") {
    url += "&home=" + country;
    const countrydata = am5geodata_data_countries2[country];
    if (countrydata) {
      continent = countrydata.continent;
    }
  }

  let animations: string[] = [];
  Object.keys(continentAnimations).forEach((key: string) => {
    if (key == continent) {
      animations.unshift(continentAnimations[key]);
    }
    else {
      animations.push(continentAnimations[key]);
    }
  });

  url += baseStep + "*" + animations.join("*");

  document.getElementById("preview")!.setAttribute("src", url);


  // Legacy stuff
  const hash = window.location.hash;
  if (hash.length > 2) {
    const ids = hash.replace(/#/, "").split(",");
    let editurl = "/world/?map=world&places=~" + ids.join("_") + "~~";
    document.getElementById("legacy")!.style.display = "";
    (document.getElementById("legacylink") as HTMLLinkElement).href = editurl;
  }

}

app();
